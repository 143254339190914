import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types'
import LineComponent from './LineComponent';

const useStyles = makeStyles((theme) => ({
    iconCont: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}))

const IconLineComponent = ({ Icon, className = '' }) => {
    const classes = useStyles();

    return (
        <Box className={classes.iconCont}>
            <Icon className={className} />
            <LineComponent />
        </Box>
    )
}

IconLineComponent.propTypes = {
    Icon: PropTypes.func
}

export default React.memo(IconLineComponent);