// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme as createMuiTheme } from '@material-ui/core/styles'

export const createTheme = () => {
    const theme = createMuiTheme({
        '*': {
            fontFamily: '"fira-sans", "sans-serif"',
        },
        typography: {
            color: '#313131',
            fontFamily: '"fira-sans", "sans-serif"',
        },
        palette: {
            primary: {
                dark: '#4A000B',
                main: '#BF1731',
                light: '#BF1731',
            },
            text: {
                primary: '#313131',
                secondary: '#F8F8F8',
                hover: '#AD2F2F'
            },
            gray: {
                main: "#ACACAC",
                dark: '#F2F2F2',
            },
            error: { main: '#BF1731' },
            background: {
                default: '#fafafa',
            }
        },
        overrides: {
            MuiPaper: {
                elevation1: {
                    overflow: 'hidden',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    border: `1.5px solid #EDEDED`
                }
            }
        }
    })
    return {
        ...theme,
    }
}

const mainTheme = createTheme();
export default mainTheme