import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import LineComponent from './LineComponent';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'fira-sans',
        zIndex: 1,
        fontSize: 45,
        fontWeight: 300,
        textAlign: 'center',
        textDecoration: 'none',
    },
    box: {
        margin: 'auto',
        position: 'relative',
    },
    line: {
        margin: 'auto',
        marginTop: 40,
    }
}))

const TitleComponent = ({ title, className = '', ...props }) => {

    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Typography {...props} className={classes.title}>
                {title}
            </Typography>
            <LineComponent className={classes.line} />
        </Box>
    )
}


export default TitleComponent;