import * as React from "react"

function ContactNameSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={55.964}
            height={58.679}
            viewBox="0 0 55.964 58.679"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 74">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 111"
                    d="M46.527 8.017h0a3.592 3.592 0 003.592 3.592h1.249a3.592 3.592 0 003.592-3.592V4.592A3.592 3.592 0 0051.368 1H4.588A3.592 3.592 0 00.996 4.592v3.425a3.592 3.592 0 003.592 3.592h1.253a3.592 3.592 0 003.592-3.592h37.09z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 112"
                    d="M42.349 48.949V22.218l3.258-9.022"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 113"
                    d="M10.356 13.196l3.258 9.022v26.731"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 64"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M13.614 22.218H42.35"
                />
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 62"
                    d="M16.329 49.2h23.306a8.479 8.479 0 018.479 8.479h0H7.85h0a8.479 8.479 0 018.479-8.479z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 63"
                    fill="#ad2f2f"
                    d="M45.106 52.207v2.464H10.857v-2.464z"
                />
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 73"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                >
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 65"
                        d="M21.299 28.734v14.702"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 66"
                        d="M27.982 28.734v14.702"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 67"
                        d="M34.665 28.734v14.702"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ContactNameSvg
