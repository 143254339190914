import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import LinkContentComponent from './LinkContentComponent';

const useStyles = (showPoints) => makeStyles((theme) => ({
    otherBoxLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',

    },
}))

const LinkComponent = ({ showPoints, to, children, title }) => {
    const classes = useStyles(showPoints)();

    return (
        <Box>
            {to
                ? <Link to={to} className={classes.otherBoxLink}>
                    <LinkContentComponent title={title} showPoints={showPoints}>
                        {children}
                    </LinkContentComponent>
                </Link>
                : <Box className={classes.otherBoxLink}> 
                    <LinkContentComponent title={title} showPoints={showPoints}>
                    {children}
                </LinkContentComponent>
                </Box>
            }
        </Box>
    )
}

LinkComponent.propTypes = {
    title: PropTypes.string,
    to: PropTypes.string,
    children: PropTypes.object,
    showPoints: PropTypes.bool,
}

export default LinkComponent;