import * as React from "react"

function AboutSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={131.004}
            height={121.327}
            viewBox="0 0 131.004 121.327"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 60">
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 59"
                    transform="translate(-725.557 -1083.93)"
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 84"
                        d="M741.239 1185.91h-8l-6.565-16.085a1.325 1.325 0 011.226-1.826h126.319a1.325 1.325 0 011.227 1.826l-6.565 16.085h-8"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 85"
                        d="M840.88 1204.258v-24.759a.9.9 0 00-.9-.9h-97.842a.9.9 0 00-.9.9v24.759"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 53"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M791.226 1167.999v-10.342"
                    />
                    <rect
                        data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 52"
                        width={10.548}
                        height={11.941}
                        rx={4.681}
                        transform="translate(785.785 1145.716)"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 58">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 57">
                            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 56">
                                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 53">
                                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 52">
                                        <path
                                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 86"
                                            d="M804.654 1125.512s13.486 7.312 18.728 11.526a14.324 14.324 0 013.152 3.581 15.912 15.912 0 012.315 8.46v18.92"
                                            fill="none"
                                            stroke="#344154"
                                            strokeLinecap="round"
                                            strokeMiterlimit={10}
                                            strokeWidth={2}
                                        />
                                    </g>
                                </g>
                                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 55">
                                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 54">
                                        <path
                                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 87"
                                            d="M777.464 1125.512s-13.486 7.312-18.728 11.526a14.324 14.324 0 00-3.151 3.581 15.912 15.912 0 00-2.315 8.46v18.92"
                                            fill="none"
                                            stroke="#344154"
                                            strokeLinecap="round"
                                            strokeMiterlimit={10}
                                            strokeWidth={2}
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <ellipse
                        data-name="\u042D\u043B\u043B\u0438\u043F\u0441 20"
                        cx={19.064}
                        cy={23.83}
                        rx={19.064}
                        ry={23.83}
                        transform="translate(771.995 1084.93)"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 88"
                        d="M775.116 1095.694a32 32 0 006.475 4.028 14.781 14.781 0 007.532 1.366 11.714 11.714 0 008.792-5.809"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 89"
                        d="M794.05 1099.371a7.536 7.536 0 008.24 3.084c2.2-.6 4-2.149 5.721-3.655"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 90"
                        d="M746.342 1183.704v12.975a319.221 319.221 0 0189.435 0v-12.975z"
                        fill="#ad2f2f"
                    />
                </g>
            </g>
        </svg>
    )
}

export default AboutSvg
