import * as React from "react"

function NipSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50.614" height="50.459" viewBox="0 0 50.614 50.459"
            {...props}
        >
            <g id="Group_76" data-name="Group 76" transform="translate(-1055.697 -1111.515)">
                <line id="Line_68" data-name="Line 68" x1="16.023" transform="translate(1072.986 1134.841)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_69" data-name="Line 69" x1="10.774" transform="translate(1073 1123.668)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_70" data-name="Line 70" x1="10.774" transform="translate(1073 1127.392)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_71" data-name="Line 71" x1="10.774" transform="translate(1073 1131.117)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_72" data-name="Line 72" x1="16.023" transform="translate(1073 1123.668)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <path id="Contour_114" data-name="Contour 114" d="M1082.542,1158.3v-23.435a1.852,1.852,0,0,1,1.852-1.852h32.234a1.852,1.852,0,0,1,1.852,1.852V1158.3" transform="translate(-19.506 -15.649)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <path id="Contour_115" data-name="Contour 115" d="M1095.716,1117.361v-3.4a1.443,1.443,0,0,1,1.443-1.443h26.82a1.443,1.443,0,0,1,1.443,1.443v3.4" transform="translate(-29.565)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_73" data-name="Line 73" y1="15.255" x2="6.041" transform="translate(1056.995 1127.392)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_74" data-name="Line 74" x1="6.041" y1="15.255" transform="translate(1098.973 1127.392)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <path id="Contour_116" data-name="Contour 116" d="M1093.519,1242.883l-.967,3.336a4.457,4.457,0,0,1-4.28,3.216h-14.534a4.457,4.457,0,0,1-4.28-3.216l-.967-3.336a4.069,4.069,0,0,0-3.909-2.936h-7.587V1254.3a3.976,3.976,0,0,0,3.976,3.976h40.067a3.976,3.976,0,0,0,3.976-3.976v-14.351h-7.587A4.069,4.069,0,0,0,1093.519,1242.883Z" transform="translate(0 -97.299)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                <path id="Contour_117" data-name="Contour 117" d="M1088.509,1278.445a44.79,44.79,0,0,1-21.677-5.216v6.475a1.652,1.652,0,0,0,1.65,1.65h40.067a1.652,1.652,0,0,0,1.65-1.65v-6.483A44.782,44.782,0,0,1,1088.509,1278.445Z" transform="translate(-7.511 -122.705)" fill="#ad2f2f" />
            </g>

        </svg>
    )
}

export default NipSvg
