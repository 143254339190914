import * as React from "react"

function LitigationSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={81.349}
            height={81.501}
            viewBox="0 0 81.349 81.501"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 12">
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 17"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M56.658 22.89l-9.027 9.027"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 18"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M34.382 18.739l9.028-9.028"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 36"
                    d="M56.653 22.885a5.409 5.409 0 007.1-.481h0a5.408 5.408 0 000-7.647L51.579 2.583a5.408 5.408 0 00-7.647 0h0a5.407 5.407 0 000 7.647l6.125 6.126"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 37"
                    d="M34.381 18.738a5.408 5.408 0 00-7.092.485h0a5.407 5.407 0 000 7.647l12.174 12.174a5.407 5.407 0 007.647 0h0a5.407 5.407 0 000-7.647l-6.412-6.413"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 38"
                    d="M57.327 28.097l22.084 22.085a3.2 3.2 0 010 4.527h0a3.2 3.2 0 01-4.527 0L52.8 32.624"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 39"
                    d="M12.214 69.287h33.23a11.214 11.214 0 0111.214 11.214h0H1h0a11.214 11.214 0 0111.214-11.214z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 40"
                    d="M52.776 76.619H4.882a3.451 3.451 0 013.451-3.45h40.993a3.45 3.45 0 013.45 3.45z"
                    fill="#ad2f2f"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 19"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M28.63 61.652v-11.43"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 20"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M36.508 63.348l8.082-8.082"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 21"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M21.15 63.348l-8.082-8.082"
                />
            </g>
        </svg>
    )
}

export default LitigationSvg
