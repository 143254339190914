import * as React from "react"

function ContactPhoneSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={35.143}
            height={56.228}
            viewBox="0 0 35.143 56.228"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 72">
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 70"
                    transform="translate(-1906.444 -827.778)"
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 107"
                        d="M1938.073 827.778h-28.114a3.512 3.512 0 00-3.515 3.514v49.2a3.512 3.512 0 003.515 3.514h28.114a3.512 3.512 0 003.514-3.514v-49.2a3.512 3.512 0 00-3.514-3.514zm1.757 52.714a1.757 1.757 0 01-1.757 1.757h-28.114a1.757 1.757 0 01-1.757-1.757v-7.029h31.629zm0-8.786h-31.63v-35.143h31.629zm0-36.9h-31.63v-3.514a1.757 1.757 0 011.757-1.757h28.114a1.757 1.757 0 011.757 1.757z"
                        fill="#344154"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 108"
                        d="M1924.016 880.493a2.636 2.636 0 10-2.636-2.636 2.634 2.634 0 002.636 2.636zm0-3.514a.879.879 0 11-.879.879.878.878 0 01.879-.88z"
                        fill="#344154"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 109"
                        d="M1924.894 831.292h-5.271a.879.879 0 000 1.757h5.271a.879.879 0 000-1.757z"
                        fill="#344154"
                    />
                    <circle
                        data-name="\u042D\u043B\u043B\u0438\u043F\u0441 24"
                        cx={0.879}
                        cy={0.879}
                        r={0.879}
                        transform="translate(1927.53 831.292)"
                        fill="#344154"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 110"
                        d="M1938.661 860.379c-6.4 3.228-13.921 7.021-19.916 10.05h19.916z"
                        fill="#ad2f2f"
                    />
                </g>
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 71"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                >
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 61"
                        d="M8.536 12.307l-2.87 2.87"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 62"
                        d="M11.741 14.203l-6.075 6.075"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 63"
                        d="M11.74 19.304l-2.52 2.52"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ContactPhoneSvg
