import { Box, makeStyles } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import ContainerComponent from './ContainerComponent';
import OfferItemComponent from './OfferItemComponent';
import InvestmentPng from '../imgs/offers/investment_m.jpg'
import CompaniesPng from '../imgs/offers/companies_m.jpg'
// import LangSupportPng from '../imgs/offers/lang_support.png'
import LitigationPng from '../imgs/offers/litigation_m.jpg'
import LawPng from '../imgs/offers/law_m.jpg'
import { classNames } from '../functions/classNames';
import { Reveal, Tween } from 'react-gsap'
import InvestmentSvg from '../svg/InvestmentSvg';
import CompaniesSvg from '../svg/CompaniesSvg';
import LawSvg from '../svg/LawSvg';
import LitigationSvg from '../svg/LitigationSvg';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        background: theme.palette.gray.dark,
        // zIndex: -1
    },
    cont: {
        // width: '100%',
        // maxWidth: 'none',
        // paddingLeft: 250,
        // paddingRight: 0,
        // marginRight: 0,
    },
    offerCont: {
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginLeft: '5vw',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        // flexBasis: '50%', 
    },
    img: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100vh',
        width: '50%',
        zIndex: 1,
        opacity: 0,
        transition: 'all 0.2s ease-in',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    title: {
        fontFamily: 'fira-sans',
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.secondary
        },
    },
    activeImg: {
        opacity: 1,
        zIndex: 0,
    },
    mobileBackground: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 1,
            background: `#FFFFFF`,
        }
    }
}))

const OffersComponent = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <ContainerComponent className={classes.box} contClassName={classes.cont} id='offer'>
            <Reveal>
                <Tween from={{ opacity: 0 }} duration={2}>
                    <Box className={classes.offerCont} >
                        <OfferItemComponent
                            Icon={CompaniesSvg}
                            imgId='companies_Img'
                            to={t('routes.companies')}
                            className={classes.title}
                            title={t('offers.companies')}
                            active
                        />
                        <OfferItemComponent
                            Icon={InvestmentSvg}
                            imgId='investment_img'
                            to={t('routes.investment')}
                            className={classes.title}
                            title={t('offers.investment')}
                        />
                        <OfferItemComponent
                            Icon={LawSvg}
                            imgId='law_img'
                            to={t('routes.law')}
                            className={classes.title}
                            title={t('offers.law')}
                        />
                        <OfferItemComponent
                            Icon={LitigationSvg}
                            imgId='litigation_img'
                            to={t('routes.litigation')}
                            className={classes.title}
                            title={t('offers.litigation')}
                        />
                        {/* <OfferItemComponent imgId='lang_support_img' to={t('routes.lang_support')} className={classes.title} titles={[t('offers.lang_support'), t('offers.germany_english'),]} /> */}
                    </Box>
                    <img src={CompaniesPng} id='companies_Img' className={classNames([classes.img, classes.activeImg])} alt={t('offers.companies')} />
                    <img src={InvestmentPng} id='investment_img' className={classes.img} alt={t('offers.investment')} />
                    <img src={LawPng} id='law_img' className={classes.img} alt={t('offers.administration')} />
                    <img src={LitigationPng} id='litigation_img' className={classes.img} alt={t('offers.litigation')} />
                    {/* <img src={LangSupportPng} id='lang_support_img' className={classes.img} alt={t('offers.lang_support')} /> */}
                </Tween>
            </Reveal>
        </ContainerComponent>
    )
}


export default OffersComponent;