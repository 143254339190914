import { Typography, withStyles } from '@material-ui/core';

const LineTypographyComponent = withStyles((theme) => ({
    root: {
        marginRight: 20,
        marginLeft: 20,
        textDecoration: 'none',
        position: 'relative',
        paddingLeft: 10,
        width: 'fit-content',
        lineHeight: 1,
        paddingRight: 10,
        '&::after': {
            zIndex: -1,
            content: '" "',
            position: 'absolute',
            width: '0',
            bottom: -5,
            top: 10,
            right: 0,
            left: 0,
            margin: 'auto',
            borderBottom: `2px solid ${theme.palette.text.hover}`,
        },
        '&:hover': {
            '&::after': {
                transition: 'all 0.2s ease-in',
                width: '80%',
            }
        }
    },
}))(Typography);


export default LineTypographyComponent;