import React from 'react';
import './App.css';
import Root from './Root';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import mainTheme from './MuiTheme';
import './translation/i18n';
// import './setupProxy';'


function App() {


  return (
          <MuiThemeProvider theme={mainTheme}>
            <CssBaseline />
                  <Root />
          </MuiThemeProvider>
  );
}

export default App;
