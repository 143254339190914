import React from 'react'
import {  TextField, makeStyles } from '@material-ui/core';
import { classNames } from '../functions/classNames';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: 0,
        fontFamily: '"fira-sans", "sans-serif"',
        '&>div': {
            minHeight: 70,
            borderRadius: 0,
            background: theme.palette.text.secondary,
            border: 'none',
        },
        '& *': {
            fontWeight: 300,
        },
        '& input, textarea': {
            fontSize: 20,
        },
        // '& label.Mui-focused': {
        //     color: theme.palette.text.primary,
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.text.secondary,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.secondary,
            },
        },
    },
    hiddenHelperText: {
        '& p': {
            opacity: 0,
        }
    }
}))

const TextFieldComponent = (
    {
        multiline,
        className = '',
        customHelperText,
        label,
        placeholder,
        fullWidth = true,
        type = 'text',
        formikProps,
        rows = 1,
        id,
        onBlur = () => { },
        onChange = () => { },
        variant = 'outlined',
        InputProps = {}
    }
) => {
    const classes = useStyles();
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
            <TextField
                error={customHelperText ? Boolean(customHelperText) : isError()}
                label={label}
                margin="normal"
                fullWidth={fullWidth}
                multiline={multiline}
                type={type}
                placeholder={placeholder}
                name={id}
                rows={rows}
                onChange={handleChange}
                onBlur={handleBlur}
                variant={variant}
            className={classNames([classes.textField, isError() || Boolean(customHelperText) ? '' : classes.hiddenHelperText, className])}
                value={value}
                helperText={customHelperText ? customHelperText : isError() ? error : 'error'}
            />
    )
}


export default TextFieldComponent;