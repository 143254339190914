import { makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom';
import IconLineComponent from './IconLineComponent';
import PropTypes from 'prop-types'
import { classNames } from '../functions/classNames';

const useStyles = makeStyles((theme) => ({
    link: {
        margin: '80px 20px',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            margin: '40px 0',
        },
        '& p': {
            transition: 'all 0.2s ease-in',
        },
        '&:after': {
            content: '""',
            border: `2px dashed ${theme.palette.text.hover}`,
            position: 'absolute',
            width: '60vw',
            height: '180px',
            left: '-5vw',
            opacity: 0,
            transition: 'all 0.2s ease-in',
            [theme.breakpoints.down('sm')]: {
                width: '90vw', 
                height: '140px',
            }
        },
        '&:hover': {
            '& p': {
                color: theme.palette.text.hover,
            },
            '& .anim-icon': {

                marginRight: '-240px',
            },
            '&:after': {
                opacity: 1
            }
        },
        '&.active': {
            '& p': {
                color: theme.palette.text.hover,
            },
            '& .anim-icon': {

                marginRight: '-240px',
            },
            '&:after': {
                opacity: 1
            }
        }
        // color:
    },
    title: {
        marginLeft: 40,
        fontSize: 20,
        maxWidth: 190,
    },
}))

const OfferItemComponent = ({ to, title, active, className = '', imgId, Icon }) => {
    const classes = useStyles();

    const onMouseEnter = useCallback(() => {
        const img = document.querySelector(`#${imgId}`)
        if (!active && img) {
            img.style.opacity = '1'
        }
        const item = document.querySelector('.main-offer-link')
        item.classList.remove('active');
    }, [imgId, active])
    const onMouseLeave = useCallback(() => {

        const img = document.querySelector(`#${imgId}`)
        if (!active && img) {
            img.style.opacity = '0'
        }
        const item = document.querySelector('.main-offer-link');
        item.classList.add('active');
    }, [imgId, active])
    return (
        <Link
            to={to}
            className={classNames(['offer-links', classes.link, active ? 'main-offer-link active' : ''])}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
        >
            <IconLineComponent
                Icon={Icon}
            />
            <Typography

                color='textPrimary'
                className={classes.title}
            >
                {title}
            </Typography>
        </Link>

    )
}

OfferItemComponent.propTypes = {
    Icon: PropTypes.func,
    title: PropTypes.string,
    to: PropTypes.string,
    imgId: PropTypes.string,
    active: PropTypes.bool,
}


export default React.memo(OfferItemComponent);