import * as React from "react"

function LawSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={85.393}
            height={79.902}
            viewBox="0 0 85.393 79.902"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 31">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 52"
                    d="M42.697 78.902h-26.17v-.468a7.41 7.41 0 017.41-7.41h2.375a6.942 6.942 0 016.942-6.943h18.884a6.942 6.942 0 016.942 6.943h2.375a7.41 7.41 0 017.411 7.41v.468z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 34"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M42.696 51.18V18.81"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 53"
                    d="M46.79 14.599a4.094 4.094 0 10-4.094 4.094 4.093 4.093 0 004.094-4.094z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 54"
                    d="M27.147 27.839a13.074 13.074 0 01-26.147 0z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 55"
                    d="M14.073 37.03a9.206 9.206 0 01-8.331-5.309h16.662a9.2 9.2 0 01-8.331 5.309z"
                    fill="#ad2f2f"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 56"
                    d="M58.246 27.839a13.073 13.073 0 0026.147 0z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 57"
                    d="M71.32 37.03a9.2 9.2 0 01-8.331-5.309h16.665a9.2 9.2 0 01-8.334 5.309z"
                    fill="#ad2f2f"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 58"
                    d="M36.174 8.079a9.235 9.235 0 0113.045 0"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 59"
                    d="M14.078 22.723V9.071a54.771 54.771 0 0157.236 0v13.652"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 60"
                    d="M47.613 64.081v-7.99a4.912 4.912 0 00-4.912-4.912h0a4.913 4.913 0 00-4.912 4.912v7.99"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
            </g>
        </svg>
    )
}

export default LawSvg
