import * as React from "react"

function CompaniesSvg(props) {
    return (
             <svg
            xmlns="http://www.w3.org/2000/svg"
            width={86.258}
            height={75.311}
            viewBox="0 0 86.258 75.311"
            {...props}
        >
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 11"
                transform="translate(1 1)"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 32"
                    d="M81.612 35.308v34.664a3.333 3.333 0 01-3.331 3.335H5.976a3.333 3.333 0 01-3.331-3.335V35.308"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 33"
                    d="M37.23 38.469a244.78 244.78 0 01-35.993-3.391 1.49 1.49 0 01-1.238-1.47V14.172a3.333 3.333 0 013.331-3.335h77.6a3.333 3.333 0 013.331 3.335v19.437a1.49 1.49 0 01-1.238 1.47 244.782 244.782 0 01-35.994 3.391"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <rect
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 38"
                    width={9.795}
                    height={9.806}
                    rx={2.664}
                    transform="translate(37.231 33.615)"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 34"
                    d="M60.493 10.837V3.904a3.9 3.9 0 00-3.9-3.9H27.666a3.9 3.9 0 00-3.9 3.9v6.933"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 14"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M13.727 53.349l-4.18 4.18"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 15"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M18.394 56.111l-8.847 8.847"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 16"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M18.394 63.54l-3.67 3.67"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 35"
                    d="M77.73 51.673L59.973 69.43H77.73z"
                    fill="#ad2f2f"
                />
            </g>
        </svg>
    )
}

export default CompaniesSvg
