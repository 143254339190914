import * as React from "react"

function RegonSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50.306" height="48.726" viewBox="0 0 50.306 48.726"
            {...props}
        >
                <g id="Group_78" data-name="Group 78" transform="translate(-1416.699 -1421.214)">
                    <path id="Contour_118" data-name="Contour 118" d="M1475.89,1465.776l2.863,2.553,4.635-5.668,3.728,3.635,11.358-9.062" transform="translate(-52.1 -27.653)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Contour_119" data-name="Contour 119" d="M1604.108,1492.328h0a1.783,1.783,0,0,0,1.777-1.786l-.042-29.769-3.568.014.042,29.759A1.784,1.784,0,0,0,1604.108,1492.328Z" transform="translate(-139.88 -29.222)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Contour_120" data-name="Contour 120" d="M1604.059,1430.4h0a1.784,1.784,0,0,1,1.784,1.784v5.572h-3.568v-5.572A1.784,1.784,0,0,1,1604.059,1430.4Z" transform="translate(-139.88 -6.202)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <line id="Линия_75" data-name="Линия 75" y2="3.845" transform="translate(1464.201 1463.113)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Contour_121" data-name="Contour 121" d="M1462.936,1436.969h3.729v34.719a8.434,8.434,0,0,1-8.434,8.434H1430.31v-3.729" transform="translate(-9.557 -11.182)" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Contour_122" data-name="Contour 122" d="M1444.151,1422.214H1417.7v43h28.066a2.339,2.339,0,0,0,2.339-2.339v-2.936h2.936a2.339,2.339,0,0,0,2.339-2.339v-35.383h-9.228" fill="none" stroke="#344154" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Contour_123" data-name="Contour 123" d="M1437.267,1559.482l-9.732-9.732v9.732Z" transform="translate(-7.454 -96.653)" fill="#ad2f2f" />
                    <path id="Contour_125" data-name="Contour 125" d="M1444.846,1447.328v11.959" transform="translate(-0.341 -3.616)" fill="none" stroke="#344154" strokeLinecap="round" strokeWidth="2" />
                    <path id="Contour_126" data-name="Contour 126" d="M1444.846,1447.329v8.343" transform="translate(-4.341)" fill="none" stroke="#344154" strokeLinecap="round" strokeWidth="2" />
                    <path id="Contour_127" data-name="Contour 127" d="M1444.846,1447.329v8.343" transform="translate(-9.341)" fill="none" stroke="#344154" strokeLinecap="round" strokeWidth="2" />
                    <path id="Contour_128" data-name="Contour 128" d="M1444.846,1447.329v5.392" transform="translate(-13.341 2.951)" fill="none" stroke="#344154" strokeLinecap="round" strokeWidth="2" />
                    <path id="Contour_129" data-name="Contour 129" d="M1444.846,1447.329v3.463" transform="translate(-18.341 4.88)" fill="none" stroke="#344154" strokeLinecap="round" strokeWidth="2" />
                </g>
        </svg>
    )
}

export default RegonSvg
