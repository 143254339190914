import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from './pl.json'
// import en from './en.json'
// import uk from './uk.json'
// import de from './de.json'
import { MAIN_LANGUAGE, ALL_LANGS } from '../constants';

const resources = {
    pl: {
        translation: pl
    },
    // en: {
    //     translation: en
    // },
    // de: {
    //     translation: de
    // },
};


i18n

    .use(initReactI18next)
    .init({
        resources,
        // lng: actualLang || getCookie(RAMI_ONLINE_LANGUAGE_COOKIES) || MAIN_LANGUAGE,
        lng: localStorage.getItem('lang') || MAIN_LANGUAGE,
        fallbackLng: ALL_LANGS,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;