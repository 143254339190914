import { Box, Container, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FullLogoSvg from '../svg/FullLogoSvg';
import AppBarItemComponent from './AppBarItemComponent';
import RedLogoSvg from '../svg/RedLogoSvg';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import capitalLetter from '../functions/capitalLetter';
import LogoSvg from '../svg/LogoSvg';
import FooterItemComponent from './FooterItemComponent';
import MenuIcon from '@material-ui/icons/Menu';
import MobileMenuComponent from './MobileMenuComponent';
import { classNames } from '../functions/classNames';
import { Link, useLocation } from 'react-router-dom';
import { PlayState, Tween, Timeline } from 'react-gsap';

const useStyles = (show, subPage, src) => makeStyles((theme) => ({
    header: {
        height: src ? subPage ? '75vh' : '100vh' : '100px',
        background: src ? 'none' : theme.palette.gray.dark,
        position: 'relative',
        '& .lazy-load-image-background': {
            width: '100%',
        }
    },
    img: {
        height: subPage ? '75vh' : '100vh',
        width: '100%',
        objectFit: 'cover',
    },
    titleCont: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignContent: 'center',
        margin: 'auto',
        paddingTop: 80,
        maxWidth: 1200,
    },
    titleContRelative: {
        position: 'relative',
        display: 'flex',
        zIndex: 1,
        alignContent: 'center',
        margin: 'auto 0 ',
    },
    title: {
        // margin: 'auto 0',
        fontFamily: 'fira-sans',
        fontSize: 60,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },
    nav: {
        position: 'absolute',
        top: 20,
        left: 0,
        width: '100%',
        zIndex: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 'none',
    },
    menu: {
        display: 'flex',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    icon: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: 0,
        margin: 'auto',
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            width: 100,
        },
    },
    langCont: {
        cursor: 'pointer',
        marginLeft: 30,
    },
    langsContainer: {
        fontSize: 20,
        position: 'absolute',
        zIndex: 20,
        transition: 'visibility 0s, opacity 0.5s linear',
        visibility: show ? "visible" : 'hidden',
        opacity: show ? "1" : '0',
    },
    arrowIcon: {
        color: theme.palette.text.secondary,
    },
    mainLang: {
        fontSize: 20,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '500px',
        paddingBottom: 40,
    },
    footerMenu: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    whiteLogo: {
        marginTop: 80,
        marginBottom: 40,
    },
    menuIcon: {
        color: src ? theme.palette.text.secondary : theme.palette.primary.main,
        width: 35,
        height: 35,
    },
    loader: {

        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        background: theme.palette.gray.dark,
        position: 'fixed',
        zIndex: 400
    }
}))

const MainContainerComponent = ({ title, src, subPage, children = '', titleClass = '' }) => {
    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const { location } = useLocation();
    const [show, setShow] = useState();
    const [langs, setLangs] = useState(i18n.languages.filter((lang) => lang !== i18n.language));
    const [lang, setLang] = useState(i18n.language);
    const [open, setOpen] = useState(false);
    const classes = useStyles(show, subPage, src)();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const handlShowLangs = useCallback(() => {
        setShow(!show)
    }, [show])
    const handleChangeLang = useCallback((index) => {
        i18n.changeLanguage(langs[index]);
        localStorage.setItem('lang', langs[index]);
        let newLangs = [...langs];
        newLangs[index] = lang;
        setLang(langs[index]);
        setLangs(newLangs);
        handlShowLangs();
    }, [langs, lang, handlShowLangs, i18n])

    const handleOpen = useCallback(() => {
        setOpen(!open)
    }, [open])

    useEffect(() => {
        window.scrollTo(0, 0)
        setShowLoader(true)
        // eslint-disable-next-line
    }, [location])

    return (
        <>
            <Timeline
                playState={showLoader ? PlayState.play : PlayState.stop}
                target={
                    <div className={classes.loader} />
                }
            >
                <Tween from={{ y: 0 }} to={{ y: 0 }} duration={0.25} ease="back.out(3)" />
                <Tween from={{ y: 0 }} to={{ y: '100vh' }} duration={2} ease="back.out(3)" />
            </Timeline>
            <Box component='header' className={classes.header}>
                <Container className={classes.nav}>
                    <Link to={t('routes.main')}>
                        <FullLogoSvg primary={!src} />
                    </Link>
                    {!matches
                        ? <Box className={classes.menu}>
                            <AppBarItemComponent primary={!src} title={t('app_bar.main')} to={t('routes.main')} />
                            <AppBarItemComponent primary={!src} title={t('app_bar.offer')} to={`${t('routes.main')}#offer`} />
                            <AppBarItemComponent primary={!src} title={t('app_bar.about')} to={t('routes.about')} />
                            <AppBarItemComponent primary={!src} title={t('app_bar.contact')} to={t('routes.contact')} />
                            {/* <Box className={classes.langCont}>
                                <Box display='flex' alignItems='center' onClick={handlShowLangs}>
                                    <Typography variant='body1' color='textSecondary' className={classes.mainLang}>{capitalLetter(lang)}</Typography>
                                    {show ? <ChevronLeftIcon className={classes.arrowIcon} /> : <ChevronRightIcon className={classes.arrowIcon} />}
                                </Box>
                                <Box className={classes.langsContainer}>
                                    {langs.map((l, index) => <Typography
                                        key={index}
                                        className={classes.lang}
                                        color='textSecondary'
                                        variant='body1'
                                        onClick={() => handleChangeLang(index)}>
                                        {capitalLetter(l)}
                                    </Typography>)}
                                </Box>
                            </Box> */}
                        </Box>
                        : null
                    }
                    {matches
                        ? <Box>
                            <IconButton onClick={handleOpen}>
                                <MenuIcon className={classes.menuIcon} />
                            </IconButton>
                        </Box>
                        : null
                    }

                </Container>
                {src
                    ? <LazyLoadImage
                        alt={title}
                        effect="blur"
                        src={src}
                        className={classes.img}
                    />
                    : null
                }
                <Container className={classes.titleCont}>
                    <Box className={classes.titleContRelative}>
                        <Typography variant='h1' color='textSecondary' className={classNames([classes.title, titleClass])} dangerouslySetInnerHTML={{ __html: title }} />
                        {!subPage ? <RedLogoSvg className={classes.icon} id='logo' /> : null}
                    </Box>
                </Container>
            </Box>
            <Box component='main'>
                {children}
            </Box>
            <Box component='footer' className={classes.footer}>
                <LogoSvg className={classes.whiteLogo} />
                <Box className={classes.footerMenu}>
                    <FooterItemComponent title={t('footer.main')} to={t('routes.main')} />
                    <FooterItemComponent title={t('footer.offer')} to={`${t('routes.main')}#offer`} />
                    <FooterItemComponent title={t('footer.about')} to={t('routes.about')} />
                    <FooterItemComponent title={t('footer.contact')} to={t('routes.contact')} />
                    <FooterItemComponent title={t('footer.privacy')} to={t('routes.privacy')} last />
                </Box>
            </Box>
            <MobileMenuComponent open={open} langs={langs} handleChangeLang={handleChangeLang} handleOpen={handleOpen} />
        </>
    )
}


export default MainContainerComponent;