import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = (showPoints) => makeStyles((theme) => ({
    mobilePoint: {
        display: showPoints ? 'block' : 'none',
        width: 10,
        height: 10,
        marginRight: 20,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    other: {
        fontSize: 26,
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
}))

const LinkContentComponent = ({ children, title, showPoints}) => {
    const classes = useStyles(showPoints)();

    return (
        <>
            <Box className={classes.mobilePoint}></Box>
            <Typography className={classes.other} color='textPrimary' dangerouslySetInnerHTML={{ __html: title }} />
            {children}
        </>
    )
}


LinkContentComponent.propTypes = {
   name: PropTypes.string,
}
export default LinkContentComponent;