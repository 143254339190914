import {  Container, Dialog, IconButton, makeStyles } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
// import capitalLetter from '../functions/capitalLetter';
import redBackgroundPng from '../imgs/redBackground.png';
import AppBarItemComponent from './AppBarItemComponent';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 'none',
        width: '100vw',
        height: '100vh',
        margin: 0,
        maxHeight: 'none',
        borderRadius: 0,
        background: `url(${redBackgroundPng})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    cont: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    lang: {
        fontSize: 25,
    },
    menuBtn: {
        zIndex: 30,
        position: 'absolute',
        right: 24,
        top: 24,
    },
    menuIcon: {
        color: theme.palette.text.secondary,
        width: 35,
        height: 35,
    }
}))
const MobileMenuComponent = ({ langs = [], handleChangeLang = () => { }, open, handleOpen = () => {}}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Dialog  onClose={handleOpen}  classes={{ paper: classes.dialog }} aria-labelledby="simple-dialog-title" open={open}>
            <IconButton onClick={handleOpen} className={classes.menuBtn}>
                <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <Container className={classes.cont}>
                <AppBarItemComponent onClick={handleOpen} title={t('app_bar.main')} to={t('routes.main')} active large />
                <AppBarItemComponent onClick={handleOpen}  title={t('app_bar.offer')} to={`${t('routes.main')}#offer`} large />
                <AppBarItemComponent onClick={handleOpen}  title={t('app_bar.about')} to={t('routes.about')} large />
                <AppBarItemComponent onClick={handleOpen}  title={t('app_bar.contact')} to={t('routes.contact')} large />
                {/* {langs?.length ? <Grid container justify='center'>
                    {langs.map((lang, key) =>
                        <Grid key={key} item >
                            <IconButton onClick={() => handleChangeLang(key)}>
                                <Typography color='textSecondary' className={classes.lang}>{capitalLetter(lang)}</Typography>
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                    : null} */}
            </Container>
        </Dialog>
    )
}


export default MobileMenuComponent;