import React from 'react'
import { makeStyles } from '@material-ui/core';
import { classNames } from '../functions/classNames';

const useStyles = makeStyles((theme) => ({
    line: {
        width: 70,
        height: 1,
        marginTop: 5,
        background: theme.palette.text.hover,
        transition: 'all 0.2s ease-in',
    }
}))

const LineComponent = ({className = ''}) => {
    const classes = useStyles();

    return (
        <div className={classNames([classes.line, 'anim-icon', className])}></div>
    )
}


export default LineComponent;