import React from 'react'
import { useTranslation } from 'react-i18next';
import MainContainerComponent from '../../components/MainContainerComponent';
import OffersComponent from '../../components/OffersComponent';
import OfficeComponent from '../../components/OfficeComponent'
import HeroImg from '../../imgs/mainPage/hero.jpg'
const MainPage = (props) => {
  const {t} = useTranslation();
    return (
      <MainContainerComponent title={t('main_page.header')} src={HeroImg}>
          <OfficeComponent />
          <OffersComponent />
      </MainContainerComponent>
    )
}


export default MainPage;