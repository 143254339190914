import useTheme from "@material-ui/core/styles/useTheme"
import * as React from "react"

function FullLogoSvg(props) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={118.833}
            height={55.368}
            viewBox="0 0 118.833 55.368"
            // {...props}
        >
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 1"
                fill={props.primary ? theme.palette.primary.main : '#fff'}
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1"
                    d="M118.782 29.669l-.241-.025h-.243a3.243 3.243 0 00-1.2.218 4.812 4.812 0 00-1.033.565 7.582 7.582 0 00-.926.784c-.29.288-.584.568-.873.843a3.751 3.751 0 01.924-.1 2.369 2.369 0 012.053.824 3.623 3.623 0 01.6 2.166 8.671 8.671 0 01-.123 1.47c-.082.478-.17.961-.268 1.446a5.852 5.852 0 00-.122 1.143 4.131 4.131 0 00.2 1.252 2.48 2.48 0 00.681 1.081 6.374 6.374 0 01-.5.221 1.528 1.528 0 01-.525.1 1.041 1.041 0 01-1.019-.512 2.453 2.453 0 01-.271-1.164 3.769 3.769 0 01.052-.623c.03-.2.063-.407.1-.619.132-.649.256-1.3.378-1.957a11.056 11.056 0 00.182-2.006 2.646 2.646 0 00-.463-1.69 2.026 2.026 0 00-1.652-.57 3.081 3.081 0 00-.8.122q-.756.73-1.542 1.46a9.919 9.919 0 01-1.691 1.26l-1.117 5.569a.559.559 0 00-.038.27 1.874 1.874 0 00.038.265 1.373 1.373 0 01-.425.144 3.358 3.358 0 01-.45.025 3 3 0 01-.39-.025.482.482 0 01-.317-.166 2.572 2.572 0 00.293-.852l3.183-14.929a1.218 1.218 0 01.731-.192 1.423 1.423 0 01.29.035.346.346 0 01.243.157l-1.871 8.874a8.612 8.612 0 001.165-.839c.377-.317.741-.645 1.094-1s.708-.7 1.05-1.058.687-.695 1.043-1.021c.373-.358.752-.717 1.131-1.081a3.607 3.607 0 011.348-.815c.048-.016.136-.043.257-.072a1.133 1.133 0 01.252-.05 1.2 1.2 0 01.61.152.567.567 0 01.268.544v.2z"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 2"
                    d="M108.924 29.478q-1.312 2.067-2.606 4.083a327.064 327.064 0 00-2.552 4.084q-1.021 1.657-1.978 3.333t-1.983 3.331a7.025 7.025 0 01-.853 1.021 6.729 6.729 0 01-1.131.908 6.141 6.141 0 01-1.285.646 3.755 3.755 0 01-1.3.244 4.041 4.041 0 01-.707-.062 2.27 2.27 0 01-.677-.22 1.417 1.417 0 01-.523-.426 1.068 1.068 0 01-.2-.679v-.195l.018-.194a3.733 3.733 0 011.013-1.909 4.729 4.729 0 011.907-1.08l-.026.174a1.947 1.947 0 00-.664.375 3.16 3.16 0 00-.924 1.345 2.416 2.416 0 00-.135.783.769.769 0 00.135.469.981.981 0 00.338.294 1.466 1.466 0 00.45.145 3.13 3.13 0 00.485.037 3.805 3.805 0 001.252-.217 5.238 5.238 0 001.179-.6 6.1 6.1 0 001.021-.85 6.788 6.788 0 00.8-.984c.148-.257.292-.51.428-.755s.276-.492.425-.752a26.2 26.2 0 01-1.373-5.373q-.405-2.745-.574-5.517c-.014-.228-.032-.507-.049-.843a1.423 1.423 0 00-.219-.765 1.871 1.871 0 01.743-.241 7.37 7.37 0 01.767-.047h.314c0 .179-.006.361-.01.547s-.015.369-.015.545a44.161 44.161 0 00.293 5.008 23.844 23.844 0 001.069 4.908q1-1.6 1.983-3.2a88.884 88.884 0 001.911-3.285 25.089 25.089 0 001.479-2.987 5.237 5.237 0 00.293-.827 1.084 1.084 0 01.558-.658l.16-.061.181-.011a.818.818 0 01.4.1.381.381 0 01.185.364"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 3"
                    d="M97.025 29.139q-2.526 3.042-5.08 6t-5.082 6.005c.226.015.45.027.669.027h1.869a7.387 7.387 0 001.205-.1 8.041 8.041 0 001.4-.341 11.681 11.681 0 001.408-.583 12.1 12.1 0 001.338-.768 13.859 13.859 0 001.178-.861c-.225.325-.467.638-.717.948a7.233 7.233 0 01-.815.853 5.225 5.225 0 01-.949.667 3.468 3.468 0 01-1.092.376l-.169.084-.173.036q-1.215 0-2.407-.023c-.795-.019-1.594-.021-2.4-.021-.354 0-.7 0-1.044.008s-.689.021-1.047.036q2.429-2.915 4.862-5.773t4.888-5.748a22.373 22.373 0 00-1.362-.05 7.791 7.791 0 00-3.234.67 12.115 12.115 0 00-2.8 1.762 7.8 7.8 0 011.567-1.628 8.529 8.529 0 011.909-1.108 10.043 10.043 0 012.139-.618 13.149 13.149 0 012.262-.192 4.1 4.1 0 01.864.082 4.849 4.849 0 01.814.256"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 4"
                    d="M85.433 31.669a2.946 2.946 0 00-.825-1.667 2.578 2.578 0 00-1.775-.548 3.138 3.138 0 00-1.591.417 4.394 4.394 0 00-1.265 1.1 7.347 7.347 0 00-.949 1.555 11.982 11.982 0 00-.656 1.787 13.678 13.678 0 00-.377 1.8 11.885 11.885 0 00-.124 1.595 3.834 3.834 0 00.792 2.589 3.194 3.194 0 002.541.913 4.3 4.3 0 001.92-.442 6.842 6.842 0 001.629-1.092l.025.159v.2a5.286 5.286 0 01-.791.691 5.614 5.614 0 01-.971.574 5.675 5.675 0 01-1.07.376 4.269 4.269 0 01-1.032.137 5.106 5.106 0 01-1.911-.343 4.011 4.011 0 01-1.422-.948 3.956 3.956 0 01-.886-1.471 5.716 5.716 0 01-.305-1.906 8.44 8.44 0 01.524-2.871 9.526 9.526 0 011.445-2.651 7.832 7.832 0 012.225-1.955 5.472 5.472 0 012.831-.766 4.975 4.975 0 011.279.172 2.855 2.855 0 011.13.58.681.681 0 01.07.291 1.991 1.991 0 01-.046.379c-.035.154-.074.317-.121.487s-.1.331-.159.486-.1.281-.135.376"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 5"
                    d="M72.76 34.996q.412-.533.8-1.009t.731-.956a6.167 6.167 0 00.582-1.039 5.215 5.215 0 00.365-1.273.93.93 0 00.025-.243.758.758 0 00-.291-.669 1.2 1.2 0 00-.707-.206 2.159 2.159 0 00-.861.182 5.189 5.189 0 00-.829.451 8.577 8.577 0 00-.776.582l-.693.582-1.9 8.925a1.523 1.523 0 00.059.777.787.787 0 00.281.386 4.582 4.582 0 01-.656.1 8.782 8.782 0 01-.658.021c-.227 0-.45 0-.666-.01a2.557 2.557 0 01-.646-.111 2.325 2.325 0 00.8-1.009 7.759 7.759 0 00.39-1.251l1.8-8.558a.993.993 0 00.07-.387.6.6 0 00-.1-.343 4.86 4.86 0 00-.217-.291c.356-.158.712-.3 1.068-.424s.713-.256 1.071-.4c-.131.34-.243.683-.341 1.032s-.186.711-.269 1.083c.245-.211.512-.437.8-.669a7.587 7.587 0 01.935-.644 5.812 5.812 0 011.011-.475 3 3 0 011.021-.183 2.263 2.263 0 011.143.293 1.261 1.261 0 01.46 1.361 4.159 4.159 0 01-.545 1.371 6.948 6.948 0 01-.922 1.195 9.169 9.169 0 01-1.133 1c-.407.3-.812.57-1.216.813"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 6"
                    d="M67.339 29.33l-.013.051-.011.048c-.194.973-.418 1.923-.669 2.857s-.482 1.883-.693 2.855c-.229 1.055-.437 2.1-.634 3.149s-.377 2.1-.558 3.173a1.584 1.584 0 01-.449.083c-.155.01-.322.016-.5.016a1 1 0 01-.438-.1l.073-.73a8.278 8.278 0 01-.947.534 4.475 4.475 0 01-.817.3 4.04 4.04 0 01-.849.135c-.3.019-.66.024-1.08.024a4.6 4.6 0 01-1.765-.316 3.486 3.486 0 01-1.287-.9 3.854 3.854 0 01-.789-1.385 5.546 5.546 0 01-.268-1.777 7.348 7.348 0 01.17-1.6 8.385 8.385 0 01.924-2.359 10.906 10.906 0 011.629-2.21 8.71 8.71 0 012.115-1.643 4.929 4.929 0 012.433-.644 5.215 5.215 0 011.48.21 3.716 3.716 0 011.314.664.882.882 0 01.922-.752 2.523 2.523 0 01.439.049c.176.032.267.12.267.264m-1.751 1.024a6.771 6.771 0 00-1.2-.707 3.216 3.216 0 00-1.351-.29 3.286 3.286 0 00-1.9.62 7.5 7.5 0 00-1.627 1.542 10.886 10.886 0 00-1.241 1.972 8.1 8.1 0 00-.683 1.894 10.7 10.7 0 00-.265 2.312 6.708 6.708 0 00.121 1.3 3.1 3.1 0 00.427 1.093 2.164 2.164 0 00.826.753 2.731 2.731 0 001.3.277c.341 0 .672 0 1-.02a4.7 4.7 0 00.923-.138 3.1 3.1 0 00.851-.349 3.238 3.238 0 00.779-.656c.323-1.623.645-3.225.96-4.816s.678-3.182 1.081-4.787"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 7"
                    d="M56.816 29.451q-1.312 2.07-2.6 4.084t-2.552 4.088c-.277.439-.548.868-.816 1.3s-.54.871-.815 1.326c-.131.227-.251.446-.364.653s-.234.432-.364.661l-.146.229a.333.333 0 01-.267.108l-.145-.049c-.278-.809-.519-1.626-.73-2.454s-.4-1.661-.584-2.5l-.436.73c-.276.439-.548.868-.816 1.3a42.602 42.602 0 00-1.156 1.979c-.113.207-.232.432-.364.661l-.17.229a.3.3 0 01-.243.108l-.145-.049q-.435-1.241-.8-2.61t-.619-2.786q-.255-1.409-.4-2.819t-.171-2.725c0-.209-.008-.486-.024-.823a1.454 1.454 0 00-.217-.781 1.612 1.612 0 01.728-.24 6.763 6.763 0 01.78-.05h.291a19.456 19.456 0 00-.073 1.726c0 .71.031 1.471.1 2.272s.156 1.614.279 2.431.268 1.618.438 2.4a20.568 20.568 0 00.6 2.185q.607-.922 1.19-1.836c.388-.607.77-1.218 1.141-1.834q-.24-1.36-.389-2.709c-.094-.9-.152-1.811-.167-2.738 0-.209-.012-.486-.027-.823a1.411 1.411 0 00-.218-.781 1.607 1.607 0 01.717-.24 6.515 6.515 0 01.767-.05h.315a21.03 21.03 0 00-.096 2.091 28.016 28.016 0 00.217 3.477c.357-.648.7-1.314 1.022-1.981s.624-1.358.9-2.057c.113-.273.21-.548.291-.813a1.186 1.186 0 01.558-.667.8.8 0 01.366-.074.806.806 0 01.377.1.375.375 0 01.18.362l-3.573 5.621c.131.841.277 1.677.439 2.5a18.486 18.486 0 00.655 2.454q1.461-2.208 2.9-4.618a37.009 37.009 0 002.468-4.863 3.413 3.413 0 00.17-.5 2.386 2.386 0 01.193-.512 1.255 1.255 0 01.317-.389.834.834 0 01.534-.157.807.807 0 01.378.1.372.372 0 01.182.362"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 8"
                    d="M39.965 29.33l-.013.051-.011.048c-.2.973-.418 1.923-.669 2.857s-.483 1.883-.693 2.855a126.242 126.242 0 00-1.191 6.322 1.618 1.618 0 01-.45.083c-.153.01-.32.016-.5.016a.99.99 0 01-.438-.1l.073-.73a8.456 8.456 0 01-.948.534 4.5 4.5 0 01-.814.3 4.091 4.091 0 01-.85.135c-.3.019-.662.024-1.083.024a4.578 4.578 0 01-1.762-.316 3.477 3.477 0 01-1.289-.9 3.868 3.868 0 01-.789-1.385 5.574 5.574 0 01-.269-1.777 7.287 7.287 0 01.17-1.6 8.482 8.482 0 01.925-2.359 10.906 10.906 0 011.629-2.21 8.672 8.672 0 012.115-1.643 4.937 4.937 0 012.43-.644 5.221 5.221 0 011.483.21 3.7 3.7 0 011.313.664.885.885 0 01.922-.752 2.484 2.484 0 01.44.049c.177.032.267.12.267.264m-1.75 1.024a6.824 6.824 0 00-1.2-.707 3.208 3.208 0 00-1.35-.29 3.284 3.284 0 00-1.9.62 7.491 7.491 0 00-1.629 1.542 11.048 11.048 0 00-1.24 1.972 7.94 7.94 0 00-.68 1.894 10.58 10.58 0 00-.267 2.312 6.787 6.787 0 00.121 1.3 3.116 3.116 0 00.428 1.093 2.161 2.161 0 00.825.753 2.731 2.731 0 001.3.277c.343 0 .675 0 1-.02a4.716 4.716 0 00.926-.138 3.129 3.129 0 001.627-1c.325-1.623.643-3.225.959-4.816s.679-3.182 1.084-4.787"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 9"
                    d="M29.081 29.746a3.22 3.22 0 00-1.068-.146c-.439 0-.806-.007-1.1-.027-.374 1.673-.735 3.332-1.094 4.987s-.7 3.313-1.046 4.982c-.015.1-.027.2-.036.292s-.011.194-.011.292a1.448 1.448 0 00.192.776 2.124 2.124 0 00.561.584 4.322 4.322 0 01-.972.1.879.879 0 01-.85-.4 1.964 1.964 0 01-.219-.957 3.94 3.94 0 01.1-.876c.065-.292.127-.572.194-.853l1.9-8.946c-.226-.017-.456-.026-.679-.038s-.455-.01-.683-.01l.074-.436c.177.016.359.027.547.039s.368.009.547.009h.291q.195-.831.34-1.629c.1-.535.171-1.089.219-1.657a3.389 3.389 0 01.829-.094 2.865 2.865 0 01.778.094 6.17 6.17 0 00-.537 1.62 69.874 69.874 0 01-.339 1.666h.363c.114 0 .257-.007.424-.009s.35-.023.536-.039.362-.038.522-.06a1.293 1.293 0 00.39-.112z"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 10"
                    d="M24.561 17.847a11.916 11.916 0 01-.381 2.2c-.2.718-.369 1.437-.517 2.157a13.841 13.841 0 00-1.389-2.157 10.119 10.119 0 00-1.817-1.8 8.8 8.8 0 00-2.2-1.21 7.106 7.106 0 00-2.541-.447 8.348 8.348 0 00-2.465.4 8.443 8.443 0 00-2.38 1.169A6.965 6.965 0 009.076 20a4.2 4.2 0 00-.717 2.377 4.433 4.433 0 001.009 2.78 16.893 16.893 0 002.536 2.585 83.502 83.502 0 003.275 2.6 35.509 35.509 0 013.277 2.805 15.388 15.388 0 012.535 3.186 7.264 7.264 0 011.01 3.771 9.8 9.8 0 01-1.594 5.274 19.823 19.823 0 01-4 4.575 26.408 26.408 0 01-5.183 3.458 22.329 22.329 0 01-5.206 1.952l-.302-.394a31.29 31.29 0 004.51-1.95 23.658 23.658 0 004.355-2.942 15.437 15.437 0 003.272-3.858 9.063 9.063 0 001.283-4.716 5.8 5.8 0 00-.944-3.208 14.819 14.819 0 00-2.333-2.76 35.511 35.511 0 00-3.052-2.536 31.765 31.765 0 01-3.052-2.557 14.56 14.56 0 01-2.339-2.834 6.168 6.168 0 01-.944-3.343 5.579 5.579 0 01.181-1.435 8.2 8.2 0 011.321-3.12 9.334 9.334 0 012.342-2.312 10.764 10.764 0 013-1.429 11.591 11.591 0 013.389-.5 15.157 15.157 0 014.128.584 11.649 11.649 0 013.726 1.795"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 11"
                    d="M81.749 10.062a1.323 1.323 0 00-.279-.03h-.282a3.807 3.807 0 00-1.385.25 5.463 5.463 0 00-1.191.652 8.747 8.747 0 00-1.065.9q-.505.5-1.008.971a4.472 4.472 0 011.065-.111 2.734 2.734 0 012.368.949 4.2 4.2 0 01.682 2.5 10.314 10.314 0 01-.137 1.7c-.1.549-.195 1.1-.3 1.666a6.325 6.325 0 00-.145 1.315 4.936 4.936 0 00.223 1.441 2.879 2.879 0 00.788 1.245 6.132 6.132 0 01-.575.255 1.658 1.658 0 01-.6.112 1.2 1.2 0 01-1.176-.59 2.846 2.846 0 01-.307-1.342 4.241 4.241 0 01.056-.715c.035-.232.076-.471.111-.713.148-.748.3-1.5.434-2.254a12.452 12.452 0 00.211-2.312 3.048 3.048 0 00-.532-1.949 2.344 2.344 0 00-1.9-.657 3.589 3.589 0 00-.926.139 93.729 93.729 0 01-1.778 1.682 11.645 11.645 0 01-1.945 1.454l-1.29 6.414a.657.657 0 00-.04.31 2.454 2.454 0 00.04.308 1.637 1.637 0 01-.491.167 3.458 3.458 0 01-.517.029 3.056 3.056 0 01-.448-.029.546.546 0 01-.365-.193 2.912 2.912 0 00.337-.981l3.669-17.2a1.386 1.386 0 01.84-.226 1.527 1.527 0 01.336.044.381.381 0 01.28.182L72.35 15.668a10.778 10.778 0 001.346-.965c.428-.365.849-.752 1.259-1.151s.813-.805 1.205-1.219.793-.8 1.205-1.174l1.3-1.247a4.145 4.145 0 011.551-.943c.052-.02.149-.046.291-.084a1.346 1.346 0 01.294-.057 1.409 1.409 0 01.7.173.66.66 0 01.31.631v.228z"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 12"
                    d="M67.195 16.111a2.381 2.381 0 00-1.191-.574 8.847 8.847 0 00-1.329-.1h-5.042a17.243 17.243 0 00-.406 1.9 12.6 12.6 0 00-.157 1.962 4.414 4.414 0 00.912 2.983 3.684 3.684 0 002.929 1.05 4.97 4.97 0 002.208-.5 8.069 8.069 0 001.877-1.261l.03.42a6.423 6.423 0 01-.911.8 6.86 6.86 0 01-2.351 1.093 5.068 5.068 0 01-1.19.152 5.774 5.774 0 01-2.2-.392 4.676 4.676 0 01-1.638-1.092 4.61 4.61 0 01-1.02-1.693 6.564 6.564 0 01-.352-2.2 10.122 10.122 0 01.562-3.221 10.887 10.887 0 011.6-3.083 9.118 9.118 0 012.449-2.295 5.776 5.776 0 013.149-.9 2.9 2.9 0 012.245.855 3.251 3.251 0 01.784 2.284 7.237 7.237 0 01-.294 1.943 13.494 13.494 0 01-.659 1.864m-2.41-6.3a3.562 3.562 0 00-1.777.448 5.041 5.041 0 00-1.4 1.163 8.261 8.261 0 00-1.035 1.567 12.6 12.6 0 00-.715 1.7h5.688a.68.68 0 00.589-.349 3.011 3.011 0 00.349-.814 6.525 6.525 0 00.181-.94 6.782 6.782 0 00.057-.727 2.211 2.211 0 00-.477-1.5 1.832 1.832 0 00-1.457-.546"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 13"
                    d="M52.899 16.196q.477-.618.927-1.162c.3-.364.577-.734.837-1.106a7.111 7.111 0 00.675-1.19 6.068 6.068 0 00.418-1.473 1.125 1.125 0 00.03-.279.885.885 0 00-.337-.771 1.377 1.377 0 00-.812-.239 2.447 2.447 0 00-.992.213 5.756 5.756 0 00-.955.517 10.357 10.357 0 00-.9.673c-.292.242-.559.467-.8.669l-2.174 10.28a1.766 1.766 0 00-.027.366 1.6 1.6 0 00.1.532.934.934 0 00.322.449 5.217 5.217 0 01-.755.112c-.262.016-.515.026-.758.026-.262 0-.518 0-.771-.016a2.822 2.822 0 01-.742-.122 2.718 2.718 0 00.925-1.164 9.016 9.016 0 00.448-1.443l2.074-9.858a1.162 1.162 0 00.084-.448.71.71 0 00-.111-.392 4.81 4.81 0 00-.254-.338c.41-.185.82-.349 1.233-.488s.823-.295 1.232-.464c-.149.394-.28.79-.391 1.191s-.216.817-.309 1.245c.28-.243.587-.495.924-.77a9.294 9.294 0 011.076-.739 6.886 6.886 0 011.165-.548 3.444 3.444 0 011.176-.21 2.567 2.567 0 011.319.338 1.216 1.216 0 01.585 1.149 1.263 1.263 0 01-.054.419 4.877 4.877 0 01-.629 1.583 8.059 8.059 0 01-1.064 1.373 10.437 10.437 0 01-1.306 1.145 17.66 17.66 0 01-1.4.94"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 14"
                    d="M45.91 9.669l-.016.053-.011.057c-.224 1.124-.481 2.218-.772 3.292s-.555 2.171-.8 3.293q-.393 1.816-.728 3.625c-.224 1.2-.44 2.426-.645 3.655a1.928 1.928 0 01-.518.1c-.176.01-.368.016-.573.016a1.142 1.142 0 01-.505-.112l.083-.839a10.243 10.243 0 01-1.091.617 6.337 6.337 0 01-.938.349 4.635 4.635 0 01-.981.152c-.345.022-.76.031-1.247.031a5.258 5.258 0 01-2.029-.365 3.988 3.988 0 01-1.484-1.039 4.45 4.45 0 01-.911-1.6 6.449 6.449 0 01-.309-2.043 8.3 8.3 0 01.2-1.849 9.708 9.708 0 011.064-2.718 12.76 12.76 0 011.877-2.55 10.112 10.112 0 012.435-1.889 5.735 5.735 0 012.8-.744 6.278 6.278 0 011.71.241 4.238 4.238 0 011.513.77 1.019 1.019 0 011.063-.869 2.949 2.949 0 01.5.054c.205.039.308.14.308.311m-2.018 1.173a7.788 7.788 0 00-1.386-.811 3.728 3.728 0 00-1.553-.335 3.792 3.792 0 00-2.185.714 8.664 8.664 0 00-1.877 1.778 12.461 12.461 0 00-1.43 2.27 9.1 9.1 0 00-.783 2.183 12.106 12.106 0 00-.307 2.662 7.744 7.744 0 00.139 1.5 3.653 3.653 0 00.49 1.263 2.574 2.574 0 00.953.866 3.167 3.167 0 001.5.322c.392 0 .774-.008 1.148-.029a5.435 5.435 0 001.063-.154 3.77 3.77 0 00.982-.4 3.656 3.656 0 00.895-.758q.56-2.8 1.106-5.543t1.246-5.522"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 15"
                    d="M26.052 23.948c.179-1.061.413-2.5.562-3.571q.676-4.62 1.167-9.227t.717-9.265a18.05 18.05 0 00-2.242 2.042c-.779.827-1.55 1.681-2.313 2.581S22.432 8.294 21.7 9.177s-1.4 1.688-2 2.4c.178.417.367.844.561 1.279a5.934 5.934 0 01.426 1.413q-.765 1.03-1.594 2a16.3 16.3 0 01-1.772 1.773 15.109 15.109 0 00-.561-3.723 37.057 37.057 0 00-1.437-4.356 36.221 36.221 0 00-1.951-4.151 16.013 16.013 0 00-2.156-3.118q-.269.717-.518 1.39c-.163.451-.319.91-.467 1.394q-1.89 5.653-3.525 11.354T3.994 28.409a1.6 1.6 0 00-.044.452 2.486 2.486 0 00.4 1.386 5.6 5.6 0 00.946 1.122c-.328.026-.651.04-.965.04h-.966c-.569 0-1.128-.007-1.683-.023S.569 31.369 0 31.369a7.374 7.374 0 001.572-1.147 3.555 3.555 0 00.944-1.633c.088-.357.172-.712.246-1.056s.143-.695.205-1.053c.176-.746.364-1.5.557-2.245s.383-1.5.561-2.244q1.347-5.115 2.83-10.166t3.322-10.031A6.519 6.519 0 009.116.769a5.607 5.607 0 00-1.3-.766h4.711a10.015 10.015 0 012 2.041 29.226 29.226 0 011.91 2.851q.879 1.524 1.627 3.074t1.235 2.717l4.668-5.387q2.334-2.691 4.755-5.295h4.76a2.615 2.615 0 00-2.2 2.467q-.093 1.035-.184 2.111t-.177 2.11c-.241 2.276-.5 4.538-.786 6.8s-.591 4.525-.919 6.8c-.21 1.494-.306 2.1-.515 3.568z"
                />
            </g>
        </svg>
    )
}

export default FullLogoSvg
