import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import mainBackground from '../imgs/mainBackground.jpg'
import ContainerComponent from './ContainerComponent';
import { Reveal, Tween } from 'react-gsap';
import ScalesSvg from '../svg/ScalesSvg';
import IconLineComponent from './IconLineComponent';

const useStyles = makeStyles((theme) => ({
    cont: {
        background: `url(${mainBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    content: {
        fontSize: 20,
        maxWidth: 580,
        fontWeight: '300',
        marginTop: 80,
        [theme.breakpoints.down('sm')]: {
            margin: '40px 0',
        },
    },
    title: {
        fontSize: 60,
        fontFamily: 'fira-sans',
    },
    titleCont: {
        width: 'fit-content',
    },
}))

const OfficeComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <ContainerComponent className={classes.cont}>
            <Reveal>
                <Tween from={{ opacity: 0 }} duration={2}>
                    <Box>
                        <Box className={classes.titleCont}>
                            <IconLineComponent
                                Icon={ScalesSvg}
                            />
                            <Typography variant='h2' color='textSecondary'  className={classes.title}>
                                {t('office.title')}
                            </Typography>
                        </Box>


                        <Typography color='textSecondary' className={classes.content}>{t('office.content')}</Typography>
                    </Box>
                </Tween>
            </Reveal>

        </ContainerComponent >
    )
}


export default OfficeComponent;