import * as React from "react"

function InvestmentSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={98.325}
            height={81.537}
            viewBox="0 0 98.325 81.537"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 10">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 27"
                    d="M59.943 29.804H4.302L49.167 7.757l44.861 22.047h-25.13"
                    fill="rgba(0,0,0,0)"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 28"
                    d="M14.43 29.804v7.346h69.461v-7.349"
                    fill="rgba(0,0,0,0)"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 29"
                    d="M10.994 73.188v-7.349h76.339v7.349"
                    fill="rgba(0,0,0,0)"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 30"
                    d="M32.985 73.188H90.89v7.349H7.435v-7.349h13.51"
                    fill="rgba(0,0,0,0)"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 1"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M20.945 37.232v28.527"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 2"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M14.432 65.759V37.232"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 3"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M36.682 37.232v28.527"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 4"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M30.169 65.759V37.232"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 5"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M52.419 37.232v28.527"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 6"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M45.906 65.759V37.232"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 7"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M68.156 37.232v28.527"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 8"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M61.643 65.759V37.232"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 9"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M77.38 65.759V37.232"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 10"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M83.893 37.232v28.527"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 11"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M14.432 24.826v-8.268"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 12"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M83.893 24.826v-8.268"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 13"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M49.163 7.756V1"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 31"
                    d="M21.001 25.922h17.131l19.052-9.9-8.022-3.942z"
                    fill="#ad2f2f"
                />
            </g>
        </svg>
    )
}

export default InvestmentSvg
