import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    cont: {
        marginTop: 20,
        flexBasis: '25%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
        },
    },
    img: {
        objectFit: 'contain',
        width: '100%'
    }
}))

const ClientComponent = ({ src, alt, to }) => {
    const classes = useStyles();

    return (
        <Box className={classes.cont}>
            {to ? <a href={to} target='_blank' rel="noreferrer">
                <img src={src} alt={alt} className={classes.img} />
            </a>
                : <img src={src} alt={alt} className={classes.img} />
            }
        </Box>
    )
}


ClientComponent.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
}
export default ClientComponent;