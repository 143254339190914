import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import MainContainerComponent from './MainContainerComponent';
import TitleComponent from './TitleComponent';
import { Reveal, Tween } from 'react-gsap'
import IconLineComponent from './IconLineComponent';
import LinkComponent from './LinkComponent';

const useStyles = makeStyles((theme) => ({
    titleCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    mainBox: {
        paddingTop: 80,
        paddingBottom: 80,
        background: theme.palette.gray.dark,
    },
    title: {
        fontSize: 45,
        fontWeight: 300,
        textAlign: 'center',
        maxWidth: 300,
        margin: 'auto',
        marginTop: 40,
    },
    icon: {
        width: 110,
        height: 'auto',
    },
    otherTitle: {
        // margin: 'auto',
        fontSize: 45,

        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
        '&::after': {
            [theme.breakpoints.down('sm')]: {
                bottom: 5
            },

        }
    },
    titleBox: {
        flexBasis: '45%',
    },
    line: {
        flexBasis: '10%',
    },
    subtitle: {
        fontSize: 25,
        flexBasis: '55%',
        paddingLeft: '10%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            marginTop: 40,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
            fontSize: 20,
        },
        '&:before': {
            content: '""',
            borderLeft: `1px solid ${theme.palette.text.hover}`,
            position: 'absolute',
            width: 10,
            left: 0,
            height: '80%',
            top: 0,
            bottom: 0,
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        }
    },
    content: {
        margin: '120px auto',
        maxWidth: 1100,
        fontWeight: 300,
        fontSize: 20,
        fontFamily: theme.typography.fontFamily,
        overflowWrap: 'break-word',
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
            marginBottom: 40,
            fontSize: 18,
        },
        '& strong, b': {
            fontWeight: 400,
        },
        '& *': {
            maxWidth: '100%',
            height: 'auto !important',
            wordBreak: 'break-word',
        },
        '& img': {
            [theme.breakpoints.down('sm')]: {
                width: '100% !important'
            },
        },
        '& p': {
            marginTop: 20,
        },
        '& hr': {
            background: theme.palette.primary.main,
            border: `0.75px solid ${theme.palette.primary.main}`,
        },
        '& h2': {
            fontWeight: 550,
            fontSize: 20
        },
        '& ol ol, ol ul': {
            fontSize: 18,
            '& li::before': {
                marginRight: 10,
                color: theme.palette.text.primary,
                fontSize: 22,
                lineHeight: 20,
            },
        },
        '& ul, ol': {
            fontSize: 18,
            marginLeft: 20,
        },
        '& ul, ol ul': {
            '& li::before': {
                color: theme.palette.text.primary,
                fontWeight: 550,
                display: 'inline-block',
                fontSize: 22,
                width: '1em',
                lineHeight: 24,
            }
        },
        '& ol': {
            paddingLeft: 0,
            '& li::before': {
                color: theme.palette.text.primary,
                fontWeight: 900,
                marginRight: 10,
            }
        },
        '& a': {
            color: theme.palette.text.primary,
            transition: 'all 0.2s ease-in',
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
        '& table': {
            border: 'none',
            margin: '20px auto',
            borderSpacing: 0,
        },
        '& td, th': {
            borderColor: theme.palette.gray.light,
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100% !important'
            },
        },
        '& caption': {
            fontSize: 15,
            marginBottom: 10,
        },
        '& blockquote': {
            position: 'relative',
            padding: '10px 20px',
            margin: 0,
            background: theme.palette.gray.light,
            '&>p': {
                fontSize: '18px !important',
            },
        }
    },
    otherCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },



    otherBox: {
        marginTop: 40,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250,
            margin: 'auto',
            marginTop: 40,
            alignItems: 'flex-start',
            flexDirection: 'column'
        },
    },
    point: {
        width: 10,
        height: 10,
        marginLeft: 30,
        marginRight: 30,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

}));
const OfferContComponent = ({ Icon, heading, children = '', src, title, subtitle, content, other = [], subPage = true }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <MainContainerComponent title={heading} src={src} subPage={subPage}>
            <Box className={classes.mainBox}>
                <Container>
                    <Reveal >
                        <Tween from={{ opacity: 0 }} duration={2}>
                            <Box className={classes.titleCont}>
                                <Box className={classes.titleBox}>
                                    {Icon
                                        ? <IconLineComponent
                                            className={classes.icon}
                                            Icon={Icon}
                                        />
                                        : null
                                    }
                                    <Typography dangerouslySetInnerHTML={{ __html: title }} className={classes.title} />
                                </Box>
                                {subtitle ? <Typography className={classes.subtitle}>{subtitle}</Typography> : null}
                            </Box>
                        </Tween>
                    </Reveal>
                    <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: content }} />
                </Container>
                {children}
                {other?.length ?
                    <Container>
                        <Reveal >
                            <Tween from={{ opacity: 0 }} duration={2}>
                                <Box className={classes.otherCont}>
                                    <TitleComponent color='textPrimary' title={t('offer_cont.other_title')} className={classes.otherTitle} variant='h2' />
                                    <Box className={classes.otherBox}>
                                        {other.map((item, key) =>
                                            <LinkComponent key={key} title={item.title} to={item.to}>
                                                {key + 1 !== other?.length ? <Box className={classes.point}></Box> : null}
                                            </LinkComponent>
                                        )}
                                    </Box>
                                </Box>
                            </Tween>
                        </Reveal>
                    </Container>
                    : null}
            </Box>
        </MainContainerComponent>
    )
}


export default OfferContComponent;