import React from 'react'
import { useTranslation } from 'react-i18next';
import OfferContComponent from '../../components/OfferContComponent';
import HeroImg from '../../imgs/policy.jpg';

const PolicyPage = (props) => {
    const { t } = useTranslation();
    return (
        <OfferContComponent
            heading={t('policy_page.heading')}
            src={HeroImg}
            title={t('policy_page.title')}
            content={t('policy_page.content')}
        />
    )
}


export default PolicyPage;