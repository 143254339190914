import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import { classNames } from '../functions/classNames';
import LineTypographyComponent from './LineTypographyComponent';
import { HashLink } from 'react-router-hash-link';

const useStyles = (large) => makeStyles((theme) => ({
    link: {
        marginRight: 20,
        marginLeft: 20,
        marginBottom: large ? 40 : 0,
        textDecoration: 'none',
        // color:
    },
    title: {
        fontSize: large ? 40 : 20,
        '&::after': {
            borderBottom: `${large ? '4px' : '2px'} solid ${theme.palette.text.hover}`,
        },
    },
    active: {
        '&::after': {
            zIndex: -1,
            content: '" "',
            position: 'absolute',
            width: '50%',
            bottom: -5,
            top: 10,
            left: 0,
            right: 0,
            margin: 'auto',
            borderBottom: `${large ? '4px' : '2px'} solid ${theme.palette.text.hover}`,
        },
    }
}))

const AppBarItemComponent = ({ title, to, primary, large, onClick = () => {}}) => {
    const location = useLocation();
    const active = useMemo(() => location.pathname === to , [location, to])
    const classes = useStyles(large)();   
    return (
        <HashLink to={to} className={classes.link} onClick={onClick}>
            <LineTypographyComponent color={primary ? 'primary' : 'textSecondary'} className={classNames([classes.title, active ? classes.active : ''])}>
                {title}
            </LineTypographyComponent>
        </HashLink>
    )
}


export default AppBarItemComponent;