import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import IconLineComponent from '../../../components/IconLineComponent';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            padding: '0 40px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
            transition: 'all 0.2s ease-in',
            '&:hover': {
                color: theme.palette.primary.main,

            }
        }
    },
    title: {
        fontSize: 24,
        marginLeft: 40,
        fontWeight: 300,
        [theme.breakpoints.down('md')]: {
            fontSize: 25,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },

    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        transition: 'all 0.2s ease-in',
        '&:hover': {
            color: theme.palette.primary.main,

        }
    }
}))

const ContactItemComponent = ({ Icon, title, href }) => {
    const classes = useStyles();

    return (
        <Box className={classes.cont}>
            <IconLineComponent
                Icon={Icon}
            />
            {href
                ? <a href={href} target='_blank' rel="noreferrer" className={classes.link}>
                    <Typography className={classes.title} dangerouslySetInnerHTML={{ __html: title }} /> 
                </a>
                : <Typography className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
            }

        </Box>
    )
}


ContactItemComponent.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    Icon: PropTypes.func,
}
export default React.memo(ContactItemComponent);