import React from 'react'
import MainContainerComponent from '../../components/MainContainerComponent';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import TextFieldComponent from '../../components/TextFieldComponent';
import { useTranslation } from 'react-i18next';
// import HeroImg from '../../imgs/contact.jpg'
import ContactImg from '../../imgs/contact_img.jpg'
import { classNames } from '../../functions/classNames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactItemComponent from './components/ContactItemComponent';
import ContactNameSvg from '../../svg/ContactNameSvg';
import ContactAdrSvg from '../../svg/ContactAdrSvg';
import ContactPhoneSvg from '../../svg/ContactPhoneSvg';
import NipSvg from '../../svg/NipSvg';
import RegonSvg from '../../svg/RegonSvg';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        fontSize: '40px !important',
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px !important',
        },

    },
    form: {
        background: theme.palette.gray.dark,
        paddingTop: 80,
        paddingBottom: 160,
    },
    box: {
        padding: '0 40px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    btn: {
        fontSize: 40,
        textTransform: 'initial',
        width: 200,
        height: '100%',
        padding: 16,
        fontWeight: 300,
        borderRadius: '50%',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: 150,
            fontSize: 35,
        },
    },
    btnCont: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 40,
    },
    message: {
        // height: '100%',
        // '& div': {
        //     height: '100%',
        // }
    },
    imgBox: {
        marginBottom: 40,
        '& span': {
            width: '100%',
        }
    },
    img: {
        width: '100%',
        objectFit: 'contain'
    }
}))

const SignupSchema = (t) => Yup.object().shape({
    name: Yup.string()
        .required(t('errors.required'))
        .min(2, t('errors.short'))
        .max(80, t('errors.long')),
    email: Yup.string()
        .email(t('errors.email'))
        .required(t('errors.required'))
        .min(2, t('errors.short'))
        .max(80, t('errors.long')),
    message: Yup.string()
        .required(t('errors.required'))
        .min(2, t('errors.short')),
});

const ContactPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const formik = useFormik({
        validationSchema: SignupSchema(t),
        // enableReinitialize: true,
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        onSubmit: (values) => {
        },
    });
    return (
        <MainContainerComponent 
        // src={HeroImg} 
        subPage 
        // title={t('contact.title')}
         titleClass={classes.title}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Container >
                    <Grid container justify='center'>
                        <Grid item xs={12} sm={12} md={6}>
                            <ContactItemComponent
                                Icon={ContactNameSvg}
                                title={t('contact.office_name')}
                            />
                            <ContactItemComponent
                                Icon={ContactAdrSvg}
                                title={t('contact.address')}
                                href='http://maps.google.com/?q=1200 Al. Jana Pawła II 36/5, 70-453 Szczecin'
                            />
                            <ContactItemComponent
                                Icon={ContactPhoneSvg}
                                title={t('contact.phone')}
                            />
                            <ContactItemComponent
                                Icon={NipSvg}
                                title={t('contact.nip')}
                            />
                            <ContactItemComponent
                                Icon={RegonSvg}
                                title={t('contact.regon')}
                            />
                        </Grid>
                        <Grid className={classNames([classes.box, classes.imgBox])} item xs={12} sm={12} md={6}>
                            <LazyLoadImage
                                alt={t('contact.img_alt')}
                                effect="blur"
                                src={ContactImg}
                                className={classes.img}
                            />
                        </Grid>
                        <Grid className={classes.box} item xs={12} md={6}>
                            <TextFieldComponent
                                id='name'
                                fullWidth
                                placeholder={t('contact.name')}
                                formikProps={formik}
                            />
                            <TextFieldComponent
                                id='email'
                                fullWidth
                                placeholder={t('contact.email')}
                                formikProps={formik}
                            />
                        </Grid>
                        <Grid className={classes.box} item xs={12} md={6}>
                            <TextFieldComponent
                                className={classes.message}
                                id='message'
                                multiline
                                rows={7}
                                placeholder={t('contact.message')}
                                formikProps={formik}
                            />
                        </Grid>
                        <Grid className={classes.btnCont} item xs={12}>
                            <Button color='primary' variant='contained' className={classes.btn}>
                                {t('contact.send')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </MainContainerComponent>
    )
}


export default ContactPage;