import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainPage from './pages/mainPage/MainPage';
import { useTranslation } from 'react-i18next';
import InvestmentPage from './pages/investmentPage/InvestmentPage';
import CompaniesPage from './pages/companiesPage/CompaniesPage';
// import LangSupportPage from './pages/langSupportPage/LangSupportPage';
import LawPage from './pages/lawPage/LawPage';
import LitigationPage from './pages/litigationPage/LitigationPage';
import ContactPage from './pages/contactPage/ContactPage';
import AboutPage from './pages/aboutPage/AboutPage';
import PolicyPage from './pages/policyPage/PolicyPage';

const Root = (props) => {
    const { t } = useTranslation();
    return (
        <BrowserRouter >
            <Switch >
                <Route exact path={t('routes.investment')} component={InvestmentPage} />
                <Route exact path={t('routes.companies')} component={CompaniesPage} />
                <Route exact path={t('routes.litigation')} component={LitigationPage} />
                <Route exact path={t('routes.law')} component={LawPage} />
                {/* <Route exact path={t('routes.lang_support')} component={LangSupportPage} /> */}
                <Route exact path={t('routes.contact')} component={ContactPage} />
                <Route exact path={t('routes.about')} component={AboutPage} />
                <Route exact path={t('routes.privacy')} component={PolicyPage} />
                <Route exact path={t('routes.main')} component={MainPage} />
            </Switch>
        </BrowserRouter>

    )
}


export default Root;