import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react'
import { classNames } from '../functions/classNames';


const useStyles = makeStyles((theme) => ({
    box: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
    },
    cont: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        // alignItems: '',
    }
}))

const ContainerComponent = ({ children, className = '', contClassName = '', ...props }) => {
    const classes = useStyles();
    return (
        <Box className={classNames([classes.box, className])} {...props} >
            <Container className={classNames([classes.cont, contClassName])}>
                {children}
            </Container>
        </Box>
    )
}


export default ContainerComponent;