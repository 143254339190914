import React from 'react'
import { useTranslation } from 'react-i18next';
import OfferContComponent from '../../components/OfferContComponent';
import HeroImg from '../../imgs/offers/law.jpg';
import LawSvg from '../../svg/LawSvg';

const LawPage = (props) => {
    const { t } = useTranslation();
    return (
        <OfferContComponent
            // heading={t('law_page.heading')}
            src={HeroImg}
            Icon={LawSvg}
            title={t('offers.law')}
            content={t('law_page.content')}
            subtitle={t('law_page.subtitle')}
            other={[
                {
                    to: t('routes.companies'),
                    title: t('offer_cont.companies'),
                },
                {
                    to: t('routes.investment'),
                    title: t('offer_cont.investment'),
                },
                {
                    to: t('routes.litigation'),
                    title: t('offer_cont.litigation'),
                },
                // {
                //     to: t('routes.lang_support'),
                //     title: t('offer_cont.lang_support'),
                // },
            ]}
        />
    )
}


export default LawPage;