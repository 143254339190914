import * as React from "react"

function ContactAdrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={60.446}
            height={45.316}
            viewBox="0 0 60.446 45.316"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 69">
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 60"
                    d="M6.407 1H54.04a5.06 5.06 0 015.06 5.06v33.2a5.061 5.061 0 01-5.061 5.061H6.405a5.059 5.059 0 01-5.059-5.064V6.061A5.061 5.061 0 016.407 1z"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 104"
                    d="M1.346 7.875l28.877 14.782L59.1 7.875"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 68">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 105"
                        d="M21.489 23.11l7.193 3.682a3.379 3.379 0 003.081 0l7.193-3.682"
                        fill="none"
                        stroke="#344154"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                    />
                </g>
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 58"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M11.187 29.306l-2.87 2.87"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 59"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M14.392 31.202l-6.075 6.075"
                />
                <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 60"
                    fill="none"
                    stroke="#344154"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M14.391 36.303l-2.52 2.52"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 106"
                    d="M56.435 28.644C48.15 32.821 38.419 37.73 30.66 41.65h25.775z"
                    fill="#ad2f2f"
                />
            </g>
        </svg>
    )
}

export default ContactAdrSvg
