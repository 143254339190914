import { makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
    hr: {
        margin: '0 20px',
        border: 'none',

        height: 1,
        width: 50,
        background: theme.palette.text.hover,
        [theme.breakpoints.down('sm')]: {
            margin: '15px 20px',
        },
    },
    link: {
        margin: '0 20px',
        textDecoration: 'none',
        color: 'inherit',
    },
    title: {
        fontSize: 20,
    }
}))

const FooterItemComponent = ({ to, title, last }) => {
    const classes = useStyles();
    return (
        <>
            <HashLink to={to} className={classes.link}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
            </HashLink>
            {!last ? <hr className={classes.hr} /> : null}
        </>
    )
}


export default FooterItemComponent;